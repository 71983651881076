import { useContext, useEffect, useRef } from 'react';

// components
import { AudioDescriptor } from '../audioDescriptor/AudioDescriptor';
import { Player } from '../../player/Player';
import { PlayerButtons } from '../../playerButtons/PlayerButtons';
import { PlayerSlider } from '../../player/playerSlider/PlayerSlider';
import { TopBar } from '../../topBar/TopBar';

// contexts
import { AppContext } from '../../../contexts/AppContext';
import { PlayerContext } from '../../../contexts/PlayerContext';

// interfaces
import { PlayerData, PlayerProps, PlayerStylePlaylist } from '../../../interfaces/player/player.interface';
import { MobileOrientation } from '../../../interfaces/playlist/mobile.interface';

// mui
import { useTheme } from '@mui/material/styles';
import { Box, CssBaseline, Stack } from '@mui/material';

// styles
import "../../styles/truncate-text.css";

// utils
import { getSpeechCommands } from '../../../utils/speech/getSpeechCommands';
import { processCommand } from '../../../utils/speech/processCommand';
import { useLocation } from 'react-router-dom';
import { updatePlaylistOnUrlChange, updatePlaylistURL } from '../updatePlaylist';
import { AudioDescriptorMobile } from '../audioDescriptorMobile/AudioDescriptorMobile';


const backendRoute: string = process.env.REACT_APP_BACKEND_ROUTE || '';
const audioGuideUrl = `${backendRoute}/audioGuide`


interface Props {
    view?: string,
    mobileView?: boolean,
    mobileViewOrientation?: MobileOrientation
}

export const CompactPlaylist = ({ view, mobileView = false, mobileViewOrientation = 'portrait' }: Props) => {
    const location = useLocation();
    const theme = useTheme();
    const { palette } = theme;

    const playerContext = useContext(PlayerContext);
    const { style, data } = playerContext;

    const { currentAudio } = data as PlayerData;
    const { visual, playlist } = style;


    const { props } = playerContext;
    const { playerState } = props as PlayerProps;
    const { url } = playerState;

    const {
        backgroundColor,
        boxShadow,
        showOdiaLabel,
        showPlayerDescription,
        showPlayerTitle,
        truncatePlayerDescription,
        truncatePlayerTitle,
    } = visual;

    const {
        showPlaylistAudioDate,
        showPlaylistAudioDuration,
        showPlaylistAudioPublisher,
        showPlaylistAudioTopic,
        showPlaylistHeader,
        showPlaylistImages,
    } = playlist as PlayerStylePlaylist;


    const {
        appLang,
        appModals,
        appRecording,
        appTopics,
        audioGuide,
        externalCommand,
        colorMode
    } = useContext(AppContext);

    const { lang } = appLang;
    const { current: currentLanguage } = lang;

    const { topicModal } = appModals;
    const { setOpenTopicModal } = topicModal;

    const { command: extCommand, setCommand: setExtCommand, playAudioGuide, setPlayAudioGuide } = externalCommand;
    const { audioGuideActivated, setAudioGuideActivated, audioGuideNextSrc, setAudioGuideNextSrc } = audioGuide;
    const { themeMode, setDarkMode, setLightMode } = colorMode;

    const { topics: topicList } = appTopics;

    const { setVoiceCommand } = appRecording;

    const { speechCommands } = getSpeechCommands(currentLanguage)
    const commands = useRef(speechCommands);

    // update playlist on url change
    useEffect(() => {
        updatePlaylistOnUrlChange(setAudioGuideActivated, setDarkMode, setLightMode, location.search);
        // eslint-disable-next-line
    }, []);

    // update playlist url on language, theme or audio guide status change
    useEffect(() => {
        updatePlaylistURL(audioGuideActivated, themeMode.mode, location.search, location.pathname);
        // eslint-disable-next-line
    }, [themeMode, audioGuideActivated, location.search, location.pathname]);

    // update speech commands on language change
    useEffect(() => {
        commands.current = speechCommands;
        // eslint-disable-next-line
    }, [speechCommands, currentLanguage])

    // handle new external commands when they are pending to process in the state
    useEffect(() => {
        if (extCommand.pendingProcessing) {
            processCommand(
                extCommand.message,
                commands.current,
                1.0,
                currentLanguage,
                audioGuideActivated,
                playerContext,
                setVoiceCommand,
                topicList,
                setAudioGuideNextSrc,
                setPlayAudioGuide,
                setOpenTopicModal
            )
            setExtCommand(state => ({
                ...state,
                pendingProcessing: false
            }))
        }
        // eslint-disable-next-line
    }, [extCommand])

    // handle new audio guide commands when they are pending to process in the state
    useEffect(() => {
        if (playAudioGuide && audioGuideActivated) {
            const audioSrc = `${audioGuideUrl}?lang=${currentLanguage}&${audioGuideNextSrc}`
            const audio: HTMLAudioElement = new Audio(audioSrc);
            audio.play();
            setPlayAudioGuide(false);
        }
        // eslint-disable-next-line
    }, [playAudioGuide])


    const bgColor = palette.mode !== 'dark' ? backgroundColor : palette.background.paper;

    const commonStyleProps = {
        backgroundColor: bgColor,
        borderRadius: 4,
        boxShadow,
        overflow: 'hidden',
    }

    const audioDescriptionVisualStyle = (mobileViewOrientation === 'portrait')
        ? {
            showTitle: showPlayerTitle,
            showDescription: showPlayerDescription,
            showDate: mobileView ? false : showPlaylistAudioDate,
            showDuration: showPlaylistAudioDuration,
            showPublisher: showPlaylistAudioPublisher,
            showTopic: mobileView ? false : showPlaylistAudioTopic,
            showImage: url.endsWith('mp3') ? showPlaylistImages : false,
            truncateTitle: truncatePlayerTitle,
            truncateDescription: truncatePlayerDescription,
            imageSize: '60px',
            highlightSelectedAudio: false,
            addPublisherLink: false
        }
        : {
            showTitle: showPlayerTitle,
            showDescription: false,
            showDate: false,
            showDuration: false,
            showPublisher: showPlaylistAudioPublisher,
            showTopic: false,
            showImage: url.endsWith('mp3') ? showPlaylistImages : false,
            truncateTitle: false,
            truncateDescription: false,
            imageSize: '80px',
            highlightSelectedAudio: false,
            addPublisherLink: true
        }


    return (
        <>
            <CssBaseline />
            <Box
                aria-label='compactPlaylistContainer'
                sx={{ height: mobileViewOrientation === 'portrait' ? 'auto' : '100vh' }}
            >
                {
                    showPlaylistHeader &&
                    <TopBar
                        visualStyle={{ showHomeButton: false, showOdiaLabel, linkToWebsite: true }}
                        mobileViewOrientation={mobileViewOrientation}
                    />
                }
                <Box
                    aria-label='compactPlaylist'
                    sx={
                        mobileViewOrientation === 'portrait'
                            ? {
                                ...commonStyleProps,
                                maxWidth: view === "portal" ? 'md' : '96vw',
                                ml: 'auto',
                                mr: 'auto',
                                mt: 2,
                                p: 1,
                            }
                            : {
                                ...commonStyleProps,
                                width: view === "portal" ? 'md' : '100%',
                                height: 'auto',
                                ml: 1,
                                mt: 1,
                                px: 1,
                                py: 3,
                            }
                    }
                >
                    <Player />
                    {/* Current audio section */}
                    <Stack
                        direction='column'
                        spacing={mobileViewOrientation === 'portrait' ? 'inherit' : 2}
                    >
                        {url.endsWith('mp3') &&
                            <>
                                <PlayerButtons />
                                <PlayerSlider />
                            </>
                        }
                        {
                            url.endsWith('mp3') &&
                            currentAudio &&
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {mobileView
                                    ? <AudioDescriptorMobile
                                        audio={currentAudio}
                                        mobileViewOrientation={mobileViewOrientation}
                                        visualStyle={audioDescriptionVisualStyle}
                                        isPlayedAudio={true}
                                        view={view}
                                    />
                                    : <AudioDescriptor
                                        audio={currentAudio}
                                        mobileViewOrientation={mobileViewOrientation}
                                        visualStyle={audioDescriptionVisualStyle}
                                        isPlayedAudio={true}
                                        view={view}
                                    />}
                            </Box>
                        }
                    </Stack>

                </Box>
            </Box >
        </>
    )
}
