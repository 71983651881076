import { useContext } from 'react';

// components
import { AudioCard } from '../audioDescriptor/AudioCard';

// contexts
import { AppContext } from '../../../contexts/AppContext';
import { PlayerContext } from '../../../contexts/PlayerContext';

// mui
import { alpha, useTheme } from '@mui/material/styles';
import {
    Box,
    ImageList
} from '@mui/material';

// interfaces
import { PlayerData, PlayerStylePlaylist } from '../../../interfaces/player/player.interface';
import { PlaylistView } from '../../../types/playlistView';

// handlers
import { handleAudioSelect } from '../playlistHandlers';


interface Props {
    topic: string,
    view: PlaylistView,
    selected?: boolean
}


export const AudioList = ({ topic, view, selected = false }: Props) => {
    const theme = useTheme();
    const { palette } = theme;

    const { appLang } = useContext(AppContext);
    const { lang } = appLang;
    const { current: currentContextLanguage } = lang;

    const playerContext = useContext(PlayerContext);
    const { style, data } = playerContext;
    const { audioQueue } = data as PlayerData;
    const { visual, playlist } = style;

    const {
        showPlayerDescription,
        showPlayerTitle,
        truncatePlayerDescription,
        truncatePlayerTitle,
    } = visual;

    const {
        showPlaylistAudioDate,
        showPlaylistAudioDuration,
        showPlaylistAudioPublisher,
        showPlaylistAudioTopic,
        showPlaylistImages,
    } = playlist as PlayerStylePlaylist;


    const playlistView = {
        cardSize: view === 'detail' ? 180 : 220,
        columnSize: view === 'detail' ? '180px' : '230px',
        imageColumns: view === 'detail' ? 4 : 3,
        imageHeight: view === 'detail' ? 200 : 240,
        showAudioDate: view === 'detail' ? showPlaylistAudioDate : false,
        showAudioDuration: view === 'detail' ? showPlaylistAudioDuration : false,
        showAudioPublisher: view === 'detail' ? showPlaylistAudioPublisher : false,
        showAudioTopic: showPlaylistAudioTopic,
        showImages: showPlaylistImages,
    }
    const audioQueueLength = audioQueue?.filter((audio) => (
        audio.visible && audio.topicName === topic)).length;
    const imageSize = view === 'detail' ? 184 : 230;
    let width = audioQueueLength <= playlistView.imageColumns ? audioQueueLength * imageSize : undefined;

    return (
        <ImageList
            cols={selected ? playlistView.imageColumns : undefined}
            sx={
                selected
                    ? {
                        width: { width },
                        scrollbarWidth: "thin",
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            height: '6px'
                        },
                        '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 1px rgba(0,0,0,0.00)'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: alpha(palette.secondary.light, 0.2),
                            borderRadius: 2,
                        }
                    }
                    : {
                        height: playlistView.imageHeight,
                        maxWidth: { width },
                        gridAutoFlow: "column",
                        gridTemplateColumns: `repeat(auto-fill,minmax(${playlistView.columnSize},1fr)) !important`,
                        ml: 1,
                        scrollbarWidth: "thin",
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            height: '6px'
                        },
                        '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 1px rgba(0,0,0,0.00)'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: alpha(palette.secondary.light, 0.2),
                            borderRadius: 2,
                        }
                    }}
        >
            {
                audioQueue?.map((audio) => (
                    audio.visible && audio.topicName === topic &&
                    <Box
                        component={'div'}
                        key={audio.id}
                        width={playlistView.cardSize}
                        onClick={
                            () =>
                                handleAudioSelect(
                                    audio.id,
                                    playerContext,
                                    currentContextLanguage
                                )}
                        sx={{
                            cursor: 'pointer',
                            '&:hover': { opacity: 0.7 },
                            mb: selected ? "10px" : 0
                        }}

                    >
                        <AudioCard
                            audio={audio}
                            visualStyle={{
                                view,
                                showTitle: showPlayerTitle,
                                showDescription: showPlayerDescription,
                                showDate: playlistView.showAudioDate,
                                showDuration: playlistView.showAudioDuration,
                                showPublisher: playlistView.showAudioPublisher,
                                showTopic: playlistView.showAudioTopic,
                                showImage: playlistView.showImages,
                                truncateTitle: truncatePlayerTitle,
                                truncateDescription: truncatePlayerDescription
                            }}
                        />
                    </Box>
                ))
            }
        </ImageList>
    )
}
